
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { createVNode, defineComponent, ref, reactive, UnwrapRef, onMounted } from 'vue'
import { CloseCircleOutlined, HomeOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { notification, Modal } from 'ant-design-vue'

import groupService from '../../../services/group'
import validate from '../../../services/validator'
import searchService from '../../../services/filter'
import commonServices from '../../../services/common'

interface Group {
  _id?: string;
  name: string;
  description: string;
  client?: string;
}
type Key = ColumnProps['key'];
export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const loading = ref<boolean>(false)
    const visible = ref<boolean>(false)
    const edit = ref<boolean>(false)
    const editIndex = ref<number>(0)
    const groups = ref<Array<Group>>()
    const groupData = ref<Array<Group>>()
    const groupCoursFormRef = ref()
    const groupCoursRules = validate.groupCourseValidation
    const searchString = ref<string>('')
    const onSearch = async (searchValue: string) => {
      groups.value = searchService.search(groupData.value, searchValue)
    }
    const groupForm: UnwrapRef<Group> = reactive({
      name: '',
      description: '',
      client: commonServices.getCurrentProfile()._id
    })
    const groupModal = async () => {
      visible.value = true
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getGroups = async () => {
      try {
        const clientId = commonServices.getCurrentProfile()._id
        const responce = await groupService.getGroup(clientId)
        groups.value = responce.data
        groupData.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const addGroup = async (values: Group) => {
      try {
        loading.value = true
        const responce = await groupService.createGroup(values)
        if (typeof responce.data === 'string') {
          notify('Error', responce.data, 'error')
        } else {
          responce.data.total_course = 0
          groups.value.push(responce.data)
          visible.value = false
          loading.value = false
          groupCoursFormRef.value.resetFields()
          await notify('Success', 'Group added successfully', 'success')
        }
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const updateGroup = async (values: Group) => {
      try {
        loading.value = true
        const responce = await groupService.updateGroup(values._id, values)
        if (typeof responce.data === 'string') {
          notify('Error', responce.data, 'error')
        } else {
          responce.data.total_course = 0
          groups.value[editIndex.value] = responce.data
          visible.value = false
          loading.value = false
          groupCoursFormRef.value.resetFields()
          await notify('Success', 'Group updated successfully', 'success')
        }
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const editGroupModal = async (group, index) => {
      edit.value = true
      editIndex.value = index
      Object.assign(groupForm, group)
      groupModal()
    }
    const validateGroupForm = async () => {
      groupCoursFormRef.value.validate().then(() => {
        if (edit.value) {
          updateGroup(groupForm)
        } else {
          addGroup(groupForm)
        }
      }).catch((error: ValidateErrorEntity<Group>) => {
        console.log('error', error)
      })
    }
    const deleteGroup = async (group, index) => {
      try {
        await groupService.deleteGroup(group._id)
        groups.value.splice(index, 1)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const confirmDelete = async (group, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(CloseCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          await deleteGroup(group, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const columns = [
      {
        title: 'Group Name',
        onFilter: (value, record) => record.courses.indexOf(value) === 0,
        sorter: (a, b) => a.courses.length - b.courses.length,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'nameSection' }
      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'No of Course',
        dataIndex: 'total_course'
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ]
    // select table rows checkbox
    // const selectedRowKeys = ref<Key[]>([])
    // const onSelectChange = (changableRowKeys: Key[]) => {
    //   selectedRowKeys.value = changableRowKeys
    // }
    // const rowSelection = computed(() => {
    //   return {
    //     selectedRowKeys: unref(selectedRowKeys),
    //     onChange: onSelectChange,
    //     hideDefaultSelections: true,
    //     selections: [
    //       {
    //         key: 'all-data',
    //         text: 'Select All Data',
    //         onSelect: (changableRowKeys: Key[]) => {
    //           let newSelectedRowKeys = []
    //           newSelectedRowKeys = changableRowKeys
    //           selectedRowKeys.value = newSelectedRowKeys
    //         }
    //       },
    //       {
    //         key: 'odd',
    //         text: 'Select Odd Row',
    //         onSelect: (changableRowKeys: Key[]) => {
    //           let newSelectedRowKeys = []
    //           newSelectedRowKeys = changableRowKeys.filter((key, index) => {
    //             if (index % 2 !== 0) {
    //               return false
    //             }
    //             return true
    //           })
    //           selectedRowKeys.value = newSelectedRowKeys
    //         }
    //       },
    //       {
    //         key: 'even',
    //         text: 'Select Even Row',
    //         onSelect: (changableRowKeys: Key[]) => {
    //           let newSelectedRowKeys = []
    //           newSelectedRowKeys = changableRowKeys.filter((key, index) => {
    //             if (index % 2 !== 0) {
    //               return true
    //             }
    //             return false
    //           })
    //           selectedRowKeys.value = newSelectedRowKeys
    //         }
    //       }
    //     ]
    //   }
    // })
    // const hasSelected = computed(() => selectedRowKeys.value.length > 0)
    const deleteGroupCourse = async () => {
      console.log('delete')
    }
    onMounted(() => {
      getGroups()
    })
    return {
      groupModal,
      loading,
      visible,
      validateGroupForm,
      groupCoursFormRef,
      groupCoursRules,
      groupForm,
      columns,
      searchString,
      // rowSelection,
      deleteGroupCourse,
      // hasSelected,
      onSearch,
      groups,
      editGroupModal,
      edit,
      confirmDelete,
      loginAs
    }
  }
})
